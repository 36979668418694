<template>
            <span class="icon-text">
            <span class="icon">
              <i :class="(ensemble ? ensemble : 'fa')+' fa-'+nom"></i>
            </span>
            <span><slot/></span>
          </span>

</template>

<script>
export default {
    props : ['ensemble','nom']
}
</script>

<style>

</style>